.result-wish-item {
    margin: 0 0.5rem;
    cursor: pointer;
    width: 252px;
  }
  
  .result-wish-item__content {
    background-color:rgba(58, 57, 55, 0.15);
    height: 176px;
    width: 252px;
    padding: 0;
  }
  
  .result-wish-item__image {
    height: 176px;
  }
    
  .result-wish-item__info {
    position: relative;
    bottom: 98.5px;
    height: 60px;
    padding-top: 0;
    background-color: #fffdfd;
  }

  .result-wish-item__info > p {
     margin-top: 0;
  }

  .result-wish-item__buttom-info{
    display: flex;   
    justify-content: space-between; 
    align-items: flex-start;
    margin-top: -16.5px;
  }

  .name {
    height: 5.5px;
    font-size: 20px;
    letter-spacing: 0px;
    text-align: center;
    color:#4f4e4e;
 }

 .description {
  height: 7.5px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: center;
  color:#4f4e4e;
 } 
 
 .open {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.25px;
  text-align: center;
  color: #1fb95b;
  margin-right: 10px;
 }

 .close {
  font-size: 10px;
  font-weight: bold; 
  letter-spacing: 1.25px;
  text-align: center;
  color: #ff0000;
  margin-right: 10px;
 }

 .distance {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color:#4f4e4e;
  margin-left: 10px;
 }

 .remove-item-btn {
    position: relative;
    bottom: 210px;
    color: rgb(223, 7, 50);
    border-radius: 50%;
    border: 1px solid rgb(223, 7, 50);
    background-color:#ffffffce;
 }
