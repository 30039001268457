.App {
  height: 100vh;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.main {
  height: 100vh;
  overflow: hidden;
}

.left-side {
 position: absolute;
 z-index: 0;
 top: 0;
 left: 0;
 height: 100vh;
 width: 78px;
 background-color: #fff;
}

.right-side {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 100vh;
  width: 78px;
  background-color: #fff;
}
