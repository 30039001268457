.export-wl-wrapper {
  padding-top: 0rem;
  height: 100px;
}

/* .use-qr {
  padding: 3rem 0.1rem 3rem 3rem ;
}

.use-qr-gif {
  padding: 3rem 0.1rem 3rem 3rem ;
} */

/* .qr-text-line1 {
 position: relative;  
 left: 20px;
 bottom: 80px;
 font-family: Helvetica;
 font-size: 18px;
}

.qr-text-line2 {
position: relative;  
right: 92px;
bottom: 60px;
font-family: Helvetica;
font-size: 18px;
} */

/* .use-whatsapp-wrapper {
 width: 200px;
 height: 100px;
 display: flex;
 justify-content: center;
 font-family: Helvetica;
 font-size: 18px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 color: #202020;
}

.use-mail-wrapper {
 width: 200px;
 height: 100px;
 display: flex;
 justify-content: center;
 font-family: Helvetica;
 font-size: 18px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 color: #202020;
}

.use-link-wrapper {
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #202020;
   } */
   
.get-by {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.75px;
    text-align: center;
    padding-left: 10px;
    color: #000000;
}

.footer-QR-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.export-QR-wrapper-img {
  height: 200px;
  width: 33vw;
  margin-top: 55px;
}

.export-QR-wrapper-text {
  height: 200px;
  width: 25vw;
  font-size: 16px;
  margin-top: 0;
  position: relative;
  top: 54px;
  left: -50px;
}

.export-QR-wrapper-gif {
  height: 200px;
  width: 33vw;
  margin-bottom: 20px;
  border-left: 1px solid #333;
}

.export-QR-line1 {
  margin: 0;
  margin-left: -45px;
}
.export-QR-line2 {
  margin: 0;
  margin-left: -10px;
}
.export-QR-line3 {
  margin: 0;
  margin-left: -18px;
}