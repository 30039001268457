.settings-side-drawer {
  position: fixed;
  left: 2.5%;
  top: 8%;
  z-index: 100;
  height: 90vh;
  width:95%;
  /* border-radius: 25px 25px 0 0; */
  background-color: #ffffff;
}
