.menu-li-text {
    margin: 0;
    font-family: Helvetica;
    font-size: 35px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: #4f4e4e;
  }

  .MuiButton-label {
    color: #000;
    font-size: 30px;
    text-transform: none;
  }

  .new-planning-title {
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #72706b;
  }
  

  .btns-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    margin-top: -3rem;
  }

  .confirm-btn{
    background-color: #D8D8D8;
    padding: 0 1rem;
    width: 200px;
    height: 50px;
    font-size: 1.5rem;
    color: #000;
    outline: none;
    border: 10px solid #0A9849;
  }

  .cancel-btn{
    background-color: #D8D8D8;
    padding: 0 1rem;
    width: 200px;
    height: 50px;
    font-size: 1.5rem;
    color: #000;
    outline: none;
    border: 10px solid #CF000F;
  }

  .MuiMenu-paper {
    width: 405px;
    height: 495px;
  }

  .MuiPaper-elevation8 {
     box-shadow: none;
  }
  .MuiPaper-rounded {
    border-radius: 0;
  }

  .MuiButtonBase-root {
    height:80px;
  }

  .MuiButtonBase-root > img {
    padding-left: 14px;
    padding-right: 45px;
  }

  hr {
    position: relative;
    right: 25px;
    border-top: 1px solid #a0ca73;
  }

  .confirm-modal {
    border-radius: 0px;
    font-family: Helvetica;
    font-weight: 300;
    letter-spacing: -0.5px;
    background-color: #ffffff;
  }

  .confirm-modal > h4 {
    font-family: Helvetica;
    letter-spacing: -0.5px;
    font-weight: 300;
  }

  .trip-img {
    position: relative;
    right: 5px;
    top: 12px;
  }
 
