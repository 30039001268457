 .shine-text {
    /* text-transform: uppercase; */
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
     mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%); 
    -webkit-mask-size: 200%;
     mask-size: 200%;
    animation: shine 5s linear infinite;
  }
  
  @keyframes shine {
    from { -webkit-mask-position: 150%; }
    to { -webkit-mask-position: -50%; }
  }