.results-grid {
  height: 100vh;
}

.result-list-normal{
    list-style: none;
    position: absolute;
    top: 225px;
    left: 130px;
    padding: 0rem; 
    margin: 0;
    height: 50vh;
    width: 77vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .result-list-withRightDrawerOpen {
    list-style: none;
    padding: 0;
    max-width: 65%;
    /* margin-left: 40vw; */
  }
  
  .div slider-contanier {
    display: flex;
    justify-content: center;
  }
  
  .slider-contanier {
    background-color: #FFF;
    position: sticky;
    bottom: 0;
  }
  .no-found-msg-container {
    position: absolute;
    top: 40vh;
    left: 34vw;
  }
