#Carousel-2{
   position: fixed;
}

.hotel-name {
  position: fixed;
  bottom: 100px;
  left: 150px;
  font-size: 60px;
  font-family: 'Teko', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #ffffff;
  height: 6px;
}

.carousel {
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100vh;
    /* -webkit-perspective: 250px;
            perspective: 250px; */
    -webkit-perspective: 480px;
            perspective: 480px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  
  .carousel.carousel-slider {
    top: 0;
    left: 0;
  }
  
  .carousel.carousel-slider .carousel-fixed-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    z-index: 1;
  }
  
  .carousel.carousel-slider .carousel-fixed-item.with-indicators {
    bottom: 34px;
  }
  
  .carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 200px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .carousel.carousel-slider .carousel-item h2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  
  .carousel.carousel-slider .carousel-item p {
    font-size: 7.5px;
  }
  
  .carousel .carousel-item {
    visibility: hidden;
    width: 400px;
    height: 450px;
    position: absolute;
    /* top: 0; */
    top: 0px; 
    left: 0;
  }
  
  .carousel .carousel-item > img {
    width: 100%;
  }
  
  .carousel .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
  
  .carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 4px;
    width: 4px;
    margin: 12px 2px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    border-radius: 50%;
  }
  
  .carousel .indicators .indicator-item.active {
    background-color: #fff;
  }
  
  .carousel.scrolling .carousel-item .materialboxed,
  .carousel .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
  
  .tap-target-wrapper {
    width: 400px;
    height: 400px;
    position: fixed;
    z-index: 1000;
    visibility: hidden;
    -webkit-transition: visibility 0s .3s;
    transition: visibility 0s .3s;
  }
  
  .tap-target-wrapper.open {
    visibility: visible;
    -webkit-transition: visibility 0s;
    transition: visibility 0s;
  }
  
  .tap-target-wrapper.open .tap-target {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: .95; 
    -webkit-transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1), opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1), opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  .tap-target-wrapper.open .tap-target-wave::before {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .tap-target-wrapper.open .tap-target-wave::after {
    visibility: visible;
    -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    -webkit-transition: opacity .3s,
   visibility 0s 1s,
   -webkit-transform .3s;
    transition: opacity .3s,
   visibility 0s 1s,
   -webkit-transform .3s;
    transition: opacity .3s,
   transform .3s,
   visibility 0s 1s;
    transition: opacity .3s,
   transform .3s,
   visibility 0s 1s,
   -webkit-transform .3s;
  }
  
  .tap-target {
    position: absolute;
    font-size: 0.5rem;
    border-radius: 50%;
    background-color: #ee6e73;
    -webkit-box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.14), 0 10px 50px 0 rgba(0, 0, 0, 0.12), 0 30px 10px -20px rgba(0, 0, 0, 0.2);
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.14), 0 10px 50px 0 rgba(0, 0, 0, 0.12), 0 30px 10px -20px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1), opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1), opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  .tap-target-content {
    position: relative;
    display: table-cell;
  }
  
  .tap-target-wave {
    position: absolute;
    border-radius: 50%;
    z-index: 10001;
  }
  
  .tap-target-wave::before, .tap-target-wave::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
  }
  
  .tap-target-wave::before {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .tap-target-wave::after {
    visibility: hidden;
    -webkit-transition: opacity .3s,
   visibility 0s,
   -webkit-transform .3s;
    transition: opacity .3s,
   visibility 0s,
   -webkit-transform .3s;
    transition: opacity .3s,
   transform .3s,
   visibility 0s;
    transition: opacity .3s,
   transform .3s,
   visibility 0s,
   -webkit-transform .3s;
    z-index: -1;
  }
  
  .tap-target-origin {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10002;
    position: absolute !important;
  }
  
  .tap-target-origin:not(.btn):not(.btn-large):not(.btn-small), .tap-target-origin:not(.btn):not(.btn-large):not(.btn-small):hover {
    background: none;
  }
  
  @media only screen and (max-width: 600px) {
    .tap-target, .tap-target-wrapper {
      width: 300px;
      height: 300px;
    }
  }
  
  .pulse {
    overflow: visible;
    position: relative;
  }
  
  .pulse::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
    -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
  }
  
  @-webkit-keyframes pulse-animation {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }
  
  @keyframes pulse-animation {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }

  .carousel-item {
    opacity: 1 !important;
  }
