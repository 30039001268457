[data-tour-elem~=left-arrow] {
    display: none;
}

.tour-text {
    margin-top: 0;
}

.end-of-tour-btn {
    padding: 7px;
    background-color: #007AFF;
    border: none;
    border-radius: 10px;
    letter-spacing: 1px;
    color: white;
    position: absolute;
    right: 10px;
}