.confirm-modal {
    z-index: 1000;
    position: fixed;
    top: 19vh;
    left: 110px;
    width: 820px;
    height: 425px;
    border-radius: 25px;
    border: 20px solid#D8D8D8;;
    background: rgba(255, 255, 255, 0.904);
  }
    
  .confirm-modal-content {
    margin-top: 1.5rem;
    padding: 1rem 0.25rem;
    height: 40vh;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    letter-spacing: 1.6px;
    color: #000000;
  }
  
  
  
  