.weather-wrapper {
    display: flex;
    justify-content: space-between;
}

.temp-val {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.8px;
    color: #666666;
    margin-top: 3px;
    margin-right: -12px;
}


