.main-header {
  width: 100%;
  height: 78px;
  object-fit: contain;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 5;
}
