.splash-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: #F9FCFD;
}

.splash-headers {
   position: absolute;
   top: 151px;
   left: 186px;
}

.splash-headers h1{
  margin-top: 0;
  margin-bottom: 2.5px;
  margin-left: -12.5px;
  font-size: 63px;
  font-weight: 500;
  letter-spacing: 2.7px;
  color: #000000;
}

.splash-headers h2{
  margin-top: 0;
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 1.6px;
  color: #000000;
}

.splash-imgs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 150px;
  }

  @keyframes run {
    0% {
      left: -45px;
      color: #eee;
    }
    50% {
      color: #666;
    }
    100% {
      left: 45px;
      color: #eee;
    }
  }