.modal {
  z-index: 1000;
  position: fixed;
  top: 11vh;
  left: 17.5%;
  width: 63%;
  background: white;
}

.modal__header {
  width: 100%;
  height: 140px;
  padding-top: 1rem;
}

.modal__header .main-title-1 {
  padding-left: 40px;
  margin: 0;
  font-family: 'Playball', cursive;
  font-size: 30px;
  color: rgba(90, 163, 12, 0.82);
}
.modal__header .main-title-2 {
  padding-left: 200px;
  margin: 0;
  font-family: 'Playball', cursive;
  font-size: 30px;
  color: rgba(90, 163, 12, 0.82);
}

.modal__header .main-title-3 {
  padding-left: 420px;
  margin: 0;
  font-family: 'Playball', cursive;
  font-size: 30px;
  color: rgba(90, 163, 12, 0.82);
}


.modal__content {
  height: 240px;
  font-weight: 300;
  letter-spacing: 1.6px;
  background-color: #d8d8d8;
}

.modal__footer {
  padding: 0;
  height: 240px;
  text-align: center;
  background-color: #ffffff;
}


