  #simple-modal-title {
      font-size: 1.75rem;
      margin-bottom: 0;
      letter-spacing: 1.6px;
      text-align: center;
      color: #000000;
  }

  #simple-modal-description {
      font-size: 1.35rem;
      margin-top: 0;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      color: #5c5c5c;
  }

  .form-container {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5rem;
  }
 
  .input-password {
    width:10rem;
    height: 1rem;
    margin-top: 0;
    outline: none;
    padding: 8px 326px 12px 20px;
    border-radius: 23px;
    background-color: #e2e2e2;
    margin-right: 0.25rem;
    font-size: 1.35rem;
  }

  .submit-btn {
    width: 96px;
    height: 36px;
    margin-left: 0.25rem;
    font-size: 1.35rem;
    border-radius: 23px;
    background-color: #475662;
    color: #ffffff;
    outline: none;
  }

  .wrong-password {
    text-align: center;
    color: rgb(248, 39, 39);
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 1.6px;
  }