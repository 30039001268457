.footer {
    width: 100%;
    height: 78px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer > .weather-wrapper {
    margin-right: 78px;
}

.link-logo {
    margin-left: 78px;
}
