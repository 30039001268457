.carousel-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 648px;
    height: 600px;
}
.carousel.carousel-slider .control-arrow {
    width: 80px;
    padding: 0;
    height: 600px;
}

.ReactModal__Content .ReactModal__Content--after-open {
    width: 648px;
    height: 600px;
}

.carousel .carousel-slider {
    width: 648px;
    height: 600px;
    
}

.carousel.carousel-slider {
    width: 648px;
    height: 600px;
}

.slider-wrapper .axis-horizontal {
    margin-top: 100px;
}