    /* heart div class - in html i used just a simple html simbol entity for heart sign, you can use text or whatever u want*/
  .heart {
      font-size: 50px;
      color: #e00;
      animation: beat 0.8s infinite alternate;
      transform-origin: center;
      position: fixed;
      top: 5px;
      left: 530px;
      z-index: 5;
  }
  /* .heart::after {
    content: 'Go To Wishlist';
    font-size: 20px;
} */
  
  /* Heart beat animation */
  @keyframes beat{
      to { transform: scale(1.4); }
  }

  .sum {
    position: fixed;
    top: 25px;
    left: 546px;
    font-size: 15px;
    color: #000;
    z-index: 2000;
    animation: beat 0.8s infinite alternate;
  }

  .two-sum {
    position: fixed;
    top: 25px;
    left: 543px;
    font-size: 15px;
    color: #000;
    z-index: 2000;
    animation: beat 0.8s infinite alternate;
  }
  

  
  
  
  
  
  
  
  
  
  