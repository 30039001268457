.item-tiny-modal {
    z-index: 1000;
    position: fixed;
    top: 35vh;
    left: 130px;
    width: 820px;
    height: 425px;
    border-radius: 25px;
    border: 1px solid black;
    background: white;
  }
    
  .item-tiny-modal-content {
    margin-top: 1.5rem;
    padding: 0rem 0.25rem;
    height: 40vh;
    font-size: 1.75rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    color: #777777;
  }
  
  
  
  