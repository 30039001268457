.title-wrapper {
    position: absolute;
    bottom: 100px;
    width: 100%;
}

.title {
    position: absolute;
    bottom: 20px;
    left:140px;
    height: 80px;
    margin: 0;
    font-size: 65px;
    letter-spacing: 1.25px;
    text-align: left;
    color: #ffffff;
    padding-top: 8px;
    font-family: 'Teko', sans-serif;
  }