  .carusel-item__content {
    background-color: rgba(0.5, 0.5, 0.5, 0.5);
    padding: 0rem;
    width: 400px;
    /* height: 550px; */
    height: 451px;
    /* border: 1px solid black; */
  }
  
  .carusel-item__image {
    padding: 0rem;
    height: 450px;
  }
  .carusel-item__info p {
    /* font-size: 1.9rem;
    text-align: center;
    font-weight: bold;
    color: #fff; */
   
    font-size: 2.5rem;
    letter-spacing: 1.4px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    position: absolute;
    z-index: 20000;
    bottom: -41px;
    width: 100%;
    background-color: rgba(0.5, 0.5, 0.5, 0.5);
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
 }

    