.modal_input {
  z-index: 100;
  position: fixed;
  top: 10px;
  left: 78px;
  width: 405px;
  height: 4rem;
  background: white;
  outline: none;
  font-size: 1.75rem;
  padding-left: 0.5rem;
  border: none;
}

.modal_input:placeholder-shown {
  font-family: Helvetica;
  padding-left: 0.5rem;
  font-size: 30px;
  font-weight: 300;
}

::placeholder {
  color: #c6dfab;
}

.auto-container {
  position: fixed;
  border-top: 0;
  left: 78px;
  top: 78px;
  width: 428px;
  height: 210px;
  z-index: 100000;
  overflow-y:scroll;
  overflow-x: hidden;
}

.option {
  width: 100%;
  height: 50px;
  padding: 0;
  z-index: 100001;
  background-color: #ffffff;
  border-top: solid 1px #b3d48f;
  display: flex;
}

.odd-option{
  width: 100%;
  height: 50px;
  padding: 0;
  z-index: 100001;
  background-color: #ffffff;
  border-top: solid 1px #b3d48f;
  display: flex;
}

.option,
.span-option {
  font-size: 1.5rem; 
  z-index: 100002;
  align-items: center;
  padding-left: 0.50rem;
  font-size: 40px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.6px;
  text-align: right;
  color: #a1a1a1;
}

.odd-option,
.span-option {
  font-size: 25px;
  text-transform: capitalize;
  z-index: 100002;
  align-items: center;
  padding-left: 0.50rem;
}





