.modal-3d-header {
    width: 100%;
    height: 65px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-3d-modal-btn {
    width: 5%;
    background-color: #ffffff;
    border: none;
    font-size: 40px;
}

.modal-3d-header-phone {
    width: 25%;
    font-size: 30px;
}

.modal-3d-header-address {
    width: 35%;
    font-size: 30px;
}

.play-gif {
    margin-left: -23px;
    margin-top: 10px;
}
