.hotel-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
}

.hotel-header-title {
    width: 320px;
    margin: 0rem 2rem;
    padding-bottom: 0.5rem;
    font-size: 37.5px;
    font-weight: 500;
    letter-spacing: 1.6px;
    text-align: right;
    color: #000000; 
}

.hotel-search-wrapper {
  padding-right: 2.5rem;
}

.hotel-input-line {
    width: 325px;
    position: relative;
    top: -1rem;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 1.6px;
    outline: none;
    color: #000000;
    border: none;
    border-bottom: solid 2px #8a8a8a;
}

.hotel-results-grid {
    height: 100vh;
  }
  
  .hotel-result-hotel-list {
      list-style: none;
      position: absolute;
      top: 10vh;
      left: 3vh;
      padding: 0rem; 
      height: 79vh;
      width: 90vw;
      overflow-y: scroll;
    }
              
    .hotel-no-found-msg-container {
      position: absolute;
      top: 40vh;
      left: 34vw;
    }
  