.wishList-wrapper {
    width: 100%;
    height: 230px;
    background-color: #d8d8d8;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}
  
.wish-list-normal{
    list-style: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow-x: scroll;
    width: 100%;
    height: 250px;
    vertical-align: middle;
    padding: 0rem; 
    margin: 0; 
}

.wish-list-normal .wish-list-item {
    width: 100%;
    height: 176px;
    max-width: 60.333333%;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

/* tiny modal css */
.iframe-item-content {
  width: 100%;
  padding-left: 0.5rem;  
}

.tiny-modal-imgs-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    padding-left: 0.5rem;  
}

.tiny-modal-imgs-wrapper-empty {
    display: none;
}

.tiny-modal-titles-wrapper {
    margin-top: -0.5rem;
}

.tiny-modal-info-wrapper {
    margin-top: 7rem;
}

.tiny-modal-titles-wrapper .tiny-modal-title {
    font-size: 35px;
    font-weight: 500;
    margin: 0 1rem;
    letter-spacing: 1.7px;
    color: #000000;
}

.tiny-modal-titles-wrapper > h5{
    font-size: 28px;
    font-weight: 100;
    letter-spacing: 1.3px;
    margin: 0 1rem;
    color: #3b3b3b;
}

.tiny-modal-info-wrapper > h6{
    font-size: 20px;
    margin: 0 1rem;
    letter-spacing: 0.75px;
}

.retire-from-wl-btn {
    position: absolute;
    top:0.55rem;
    right: 0rem;
    width: 211px;
    height: 28px;
    border-radius: 8px 8px 0 8px;
    background-color: #c04c42;
    border: 1px solid #c04c42;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #ffffff;
    text-align: end;
    outline: none;
}
  
.back-btn-tiny-modal {
    position: absolute;
    bottom: 2rem;
    right: 0;
    width: 160px;
    height: 35px;
    border-radius:  8px 0 0 8px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    outline: none;
    background-color:#1fb95b; 
    border: 1px solid #1fb95b;

}
    

  

