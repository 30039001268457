.item-iframe {
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    padding-top: 70px;
  }
  
  .item-iframe iframe {
    width: 98%; 
    height: 90%;
  }