.item-side-drawer {
  position: fixed;
  right: 0;
  top: 78px;
  z-index: 9;
  height: 81vh;
  width: 36%;
  background-color: #ffffff;
  color: #000000;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  overflow-y: scroll;
}
