.tag-item {
    padding: 3px;
}

.tag-info {  
    padding: 3px 10px;
    margin: 0;
    border-radius: 18.5px;
    border: 1px solid #000;
    background-color: rgba(204, 204, 204, 0.418);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.75px;
}

.tag-info-active {
    padding: 3px 15px;
    margin: 0;
    border-radius: 18.5px;
    border: 1px solid  rgba(255, 255, 255, 0.637);
    background-color: #00000083;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: rgba(255, 255, 255, 0.637);
}

/* small mode */

.tag-item-small-mode {
    padding: 0.25rem;
}

.tag-info-small-mode {  
    padding: 3px 14px;
    margin: 0;
    border-radius: 18.5px;
    border: 1px solid #000;
    background-color: rgba(204, 204, 204, 0.418);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.75px;
}

.tag-info-active-small-mode {
    padding: 3px 14px;
    margin: 0;
    border-radius: 18.5px;
    border: 1px solid  rgba(255, 255, 255, 0.637);
    background-color: #00000083;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.75px;
    color: rgba(255, 255, 255, 0.637);
}

