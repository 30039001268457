.sett-title {
    font-size: 55px;
    font-weight: 500;
    letter-spacing: 2.18px;
    margin: 16px;
}

.size-sett-container{
    height: 280px;
    background-color: #D0DFEB;
}

/* Switch button CSS begin */
.switch {
  position: relative;
  top: 35px;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: rotate(90deg);
}

.switch::before {
   position: relative;
   bottom: 3.5rem;
   right: 1.5rem;;
   content: 'Big';
   font-size: 36px;
   font-weight: 500;
   letter-spacing: 1.44px;
   color: #bcbcbc;
   display: inline-block;
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -o-transform: rotate-(90deg);
   -ms-transform: rotate(-90deg);
   transform: rotate(-90deg);
}

.switch::after {
  position: relative;
  bottom: 7.5rem;
  left: 1.5rem;
  content: 'Small';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #bcbcbc;
  display: inline-block;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate-(90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(52px);
  -ms-transform: translateX(52px);
  transform: translateX(52px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Switch button CSS ends */

/* Switch-hor button CSS begin */
.switch-hor {
  position: relative;
  top: 35px;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-hor input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color:#ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Switch-hor button CSS ends */

.more-sett-container {
  width: 100%;
  height: 100px;
  background-color: #D0DFEB;
  margin-top: 34px;
  display: flex;
  justify-content: space-around;
}

.hotel-sett-container {
  width: 100%;
  height: 183px;
  background-color: #D0DFEB;
  margin-top: 35px;
  text-align: end;
}

.admin-only {
  margin: 0px 50px 0px 0px;
  padding-top: 10px;
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 1.1px;
  color: #929292;
}

.change-hotel-btn {
  width: 125px;
  height: 27px;
  font-size: 13px;
  margin: 50px 50px 0px 0px;
  padding: 3px 15px 2px 14px;
  opacity: 0.36;
  border-radius: 24px;
  background-color: #777777;
  letter-spacing: 1.4px;
  color: #ffffff;
  font-weight: 100;
  outline: 'none';
}

.current-hotel-name {
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 1.85px;
  color: #777777;
  margin-right: 1rem;
}

/* text-size */
.exemple-text {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: #777777;
}

.small-text {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: #bcbcbc;
}

.small-text-active {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: #000000;
}

.big-text {
  margin-top: 0;
  font-size: 36px;
  margin-bottom: 0px;
  font-weight: 500;
  letter-spacing: 1.44px;
  color: #bcbcbc;
}

.big-text-active {
  margin-top: 0;
  font-size: 36px;
  margin-bottom: 0px;
  font-weight: 500;
  letter-spacing: 1.44px;
  color: #000000;
}
