.hotel-result-item {
    margin: 0.5rem 0;
    cursor: pointer;
  }
  
  .hotel-result-item__content {
    background-color:rgba(58, 57, 55, 0.15);
    height: 209px;
    padding: 0;
  }
  
  .hotel-result-item__image {
    height: 209px;
  }
    
  .hotel-result-item__info {
    position: relative;
    bottom: 87px;
    height: 87px;
    padding-top: 0;
    background-color: #3737377e;
  }

  .hotel-result-item__info > p {
     margin-top: 0;
  }

  .hotel-result-item__buttom-info{
    display: flex;   
    justify-content: space-between; 
    align-items: flex-start;
    margin-top: 2.5px;
  }

  .the-hotel-name {
    height: 7px;
    font-size: 25px;
    letter-spacing: 1.1px;
    text-align: center;
    color: #ffffff;
 }

 .hotel-city {
    height: 7px;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    color: #e6e6e6;
 } 
 
