.search-icon-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
}

.menu > h3 {
  font-size: 1.5rem;
}

.menu-with-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
}

.menu-with-alert > h3 {
  font-size: 1.5rem;
}

.menu-with-alert::before{
  content: '';
  position: relative;
  bottom:23px;
  left:100px;
  z-index: 20000000;
  font-size: 1.2rem;
  width: 1.2rem;
  height: 1.2rem;
  color: #ffffff;
  border-radius: 1rem;
  background-color: rgb(232, 75, 61);
}

.weather-data {
  cursor: pointer;
  color: #000;
}

.weather-data > h3 {
  font-size: 1.5rem;
}

.slider-contanier {
  margin-left:2rem;
  display: inline-block;
}

#discrete-slider-small-steps  {
  margin-left: 3.75rem;
  margin-bottom: -0.1rem;
}

.modal-container {
  height: 15rem;
  width: 100%;
  text-align: center;
}

.input-span {
  width: 250px;
  height: 15px;
  padding: 0;
  text-align: start; 
  font-size: 18px;
  font-weight: 300; 
  letter-spacing: 1.43px;
  color: #b0b0b0;
}

.input-span hr {
  margin-top: 0.25px;
  color: #b0b0b0;
  padding: 0;
}

.update-btn {
  position: absolute;
  top: 3%;
  right: 3%;
  z-index: 20000;
  width: 13rem;
  height: 4rem;
  letter-spacing: 1.4px;
  font-size: 1.2rem;
  background-color: rgb(232, 75, 61);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  background-repeat: no-repeat;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@-ms-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}

.update-btn-hidden{
  display: none;
}

